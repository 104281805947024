import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">

        <h2>Qooky app is Coming soon</h2>
        <p>
          Impact solutions s.r.l.s. - Via Saffi, n 6 - 44042 Cento (fe)<br />
          Cap Soc 1000,00€
          CF e rag iscr reg imprese fe 02115890382<br />
          REA: FE227115  <br />
          PEC: <a href='mailto:impactsolutions@lamiapec.it'>impactsolutions@lamiapec.it</a>
        </p>

      </header>
    </div>
  );
}

export default App;
